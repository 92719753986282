<template>
  <button
    class="button-bg cursor-pointer flex justify-center items-center"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
button:disabled {
  @apply cursor-not-allowed opacity-50;
}
</style>
